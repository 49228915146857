import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {PreferencesService} from '@shared/services/main';
import {AllowedLanguagesEnum} from '@shared/enums';
import {FormStyle, getLocaleMonthNames, TranslationWidth} from '@angular/common';
import {NbNativeDateService} from '@nebular/theme';

@Injectable()
export class MkadLocaleService extends NbNativeDateService {
  constructor(
    @Inject(LOCALE_ID) override locale: string,
    private readonly preferences: PreferencesService) {
    super(preferences.userSettings.lang ?? AllowedLanguagesEnum.en);
    this.locale = this.getCurrentLang;
 }

  /**
   * @description
   * Method that returns the current language
   */
 get getCurrentLang(): string {
   return this.preferences.userSettings.lang ?? AllowedLanguagesEnum.en;
 }

  /**
   * @description
   * Method that overrides the getMonthNameByIndex method
   * @param index
   * @param style
   */
  override getMonthNameByIndex(index: number, style: TranslationWidth = TranslationWidth.Abbreviated): string {
    return getLocaleMonthNames(this.getCurrentLang, FormStyle.Format, style)[index];
  }
}

